import React from "react";

function Footer() {
  return (
    <div>
      <footer>
        <p> &#169; Copyright by Jamshed Ahmed</p>
      </footer>
    </div>
  );
}

export default Footer;
